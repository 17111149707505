<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Create Leave Group</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'leave-groups'}">
                        <span>Leave Groups</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form>
                    <div class="form-group row">
                        <label class="col-md-4">
                           Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-8">
                            <input
                                type="text"
                                class="form-control"
                                aria-describedby="nameHelpInline"
                                v-model="leave_group.name"
                                @change="textTitleCase($event, 'name')"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4">
                            Credit Frequency
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-8">
                            <b-form-group >
                                <b-form-select
                                    value-field="item"
                                    text-field="name"
                                    disabled-field="notEnabled"
                                    v-model="leave_group.credit_frequency"
                                    :options="leave_group_credit_frequency_options"
                                ></b-form-select>
                            </b-form-group>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4">
                            Number of Leaves
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-8">
                            <input
                                type="number"
                                class="form-control"
                                aria-describedby="leavesHelpInline"
                                v-model="leave_group.leaves"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4">
                            Max No. of Carry Forward Leaves
                        </label>
                        <div class="col-md-8">
                            <input
                                type="number"
                                min="0"
                                class="form-control"
                                aria-describedby="leavesHelpInline"
                                v-model="leave_group.leaves_carry_forwarded"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4">
                            Max No. of Encash Leaves
                        </label>
                        <div class="col-md-8">
                            <input
                                type="number"
                                min="0"
                                class="form-control"
                                aria-describedby="leavesHelpInline"
                                v-model="leave_group.leaves_encashed"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4">
                            Compoff Applicable For Holidays
                        </label>
                        <div class="col-md-8">
                             <b-form-checkbox-group
                                id="compoff-applicable-checkbox-id"
                                v-model="compoff_type_holidays_value"
                                :options="compoff_type_options"
                                name="compoff-applicable-checkbox-name"
                            ></b-form-checkbox-group>
                        </div>
                    </div>

                     <div class="form-group row">
                          <label class="col-md-4">
                            Compoff Applicable For Week Offs
                        </label>
                        <div class="col-md-8">
                                <b-form-checkbox-group
                                id="compoff-type-checkbox-id"
                                v-model="compoff_type_weekoffs_value"
                                :options="compoff_type_options"
                                name="compoff-type-checkbox-name"
                            ></b-form-checkbox-group>
                        </div>
                    </div>

                    <div class="form-group row" style="float:right !important;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                @click="createLeaveGroup"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            leave_group: {
                leaves:'',
                name:'',
                credit_frequency:'',
                leaves_carry_forwarded: 0,
                leaves_encashed: 0
            },
            api_error: "",
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            leave_group_credit_frequency_options: [
                { item: '', name: "Select Credit Frequency" },
                { item: "monthly", name: "Monthly" },
                { item: "quarterly", name: "Quarterly" },
                { item: "yearly", name: "Yearly" },
            ],
            compoff_type_options: [
                {
                    text: "Leaves", value:"leave",
                },
                {
                    text: "pay", value:"pay"
                }
            ],
            compoff_type_holidays_value: [],
            compoff_type_weekoffs_value: [],
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_LEAVE_GROUPS");
    },
    methods: {

        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.leave_group[key] = this.strTitleCase(event.target.value);
        },

        createLeaveGroup: function() {
            this.startProgressBar();
            let data = this.deleteEmptyKeys(this.leave_group);
            let compoff = {};
            if(this.compoff_type_holidays_value.length > 0){
                compoff['comp_off_holidays'] = this.compoff_type_holidays_value
            }

            if(this.compoff_type_weekoffs_value.length > 0){
                compoff['comp_off_week_offs'] = this.compoff_type_weekoffs_value
            }
            data['compoff'] = compoff;
            this.axios.post(this.$api.create_leave_group, data, this.getAuthHeaders()).then(() => {
                this.updateProgressBar(true);
                this.$router.push({
                    name:"leave-groups"
                });
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
                window.scrollTo(0, 0);
            });
        },
        
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";

</style>
